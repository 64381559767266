import React from 'react';
import * as s from './s.module.css';
import axios from 'axios';
import packageJson from '../../../package.json';
import CircularLoader from '../CircularLoader';
import config from '../../config';

interface Props {
    location: any,
    WAVersionUsedInDeployment: any,
}

interface State {
    webAssetsVersion: String,
    webAssetsEnviroment: String,
}

export default class Version extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            webAssetsVersion: '',
            webAssetsEnviroment: '',
        }
    }

    componentDidMount() {
        this.getCurrentWebAssetsVersion();
    }

    async getCurrentWebAssetsVersion() {
        let currentWA;
        try {
            currentWA = (await axios.get(`${config.webAssetsBaseUrl}/version.json`, {
                headers: {
                  'Cache-Control': 'no-cache',
                  'Pragma': 'no-cache',
                  'Expires': '0',
                },
              })).data;
        } catch {
            currentWA = { version: 'N/A', enviroment: 'N/A' }
        }
        this.setState({
            webAssetsVersion: currentWA.version,
            webAssetsEnviroment: currentWA.enviroment,
        });
    }

    render() {
        const content = !this.state.webAssetsVersion || !this.state.webAssetsEnviroment
        ? <CircularLoader size={60}/>
        : <>
            <p>{`Virtue Poker Website v${packageJson.version}`}</p>
            <p>{`Web Assets version used in deployment: v${this.props.WAVersionUsedInDeployment.version} (${this.props.WAVersionUsedInDeployment.enviroment} enviroment)`}</p>
            <p>{`Web Assets version currently deployed: v${this.state.webAssetsVersion} (${this.state.webAssetsEnviroment} enviroment)`}</p>
        </>

        return <section className={s.sectionContainer}>
            <div className={s.sectionContent}>
                <div className={s.container}>
                    { content }
                </div>
            </div>
        </section>
    }
}
