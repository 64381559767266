import React from "react";
import Layout from "../layout/default";
import Seo from "../components/seo";
import Version from '../components/Version';
import packageJson from '../../package.json';

const WhoWeArePage = ({ location, pageContext }) => (
    <Layout location={location} dynamicDefaultPaths={pageContext.dynamicDefaultPaths}>
        <Seo title="Version" description={`Virtue Poker v${packageJson.version}`} />
        <Version location={location} WAVersionUsedInDeployment={pageContext.WAVersionUsedInDeployment}/>
    </Layout>
)

export default WhoWeArePage;
